body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
	--primary-color: #003F60;
  --secondary-color: #49E0C3;
  --text-primary-color: #000000;
  --text-secondary-color: #FDFFFC;
  --footer-color: "#0f3749";
}