#header {
  position: relative;

  .cursor {
    z-index: 1000;
    position: fixed;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    pointer-events: none;
    display: none;
    transform: translate(-50%, -50%);
    background: radial-gradient(50% 50% at 50% 50%, rgba(253, 255, 252, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
  }
}
