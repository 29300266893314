.social-container a {
  text-decoration: none;
}

.fa-container {
  border: 1px solid var(--text-secondary-color);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  .fa {
    color: var(--text-secondary-color);
    font-size: 1em;
    transition: color 0.3s ease;
  }
}

.fa-container:hover {
  background-color: var(--text-secondary-color);
  transition: background-color 0.3s ease;
  
  .fa {
    color: var(--footer-color);
    transition: color 0.3s ease;
  }
}